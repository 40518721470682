import React from "react";
import "./FooterTwo.css";

// Icons and Images
import enamad from "../../../images/enamad.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";

const FooterTwo = () => {
    return (
        <div className="footer-two-container">
            <div className="footer-two-content">
                <div className="footer-section">
                    <h3 className="footer-title">پیندو</h3>
                    <ul className="footer-links">
                        <li><a href="#">فروش در پیندو</a></li>
                        <li><a href="#">تماس با پیندو</a></li>
                        <li><a href="#">درباره پیندو</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-title">راهنمایی مشتریان</h3>
                    <ul className="footer-links">
                        <li><a href="#">پرسش های پرتکرار</a></li>
                        <li><a href="#">شرایط استفاده</a></li>
                        <li><a href="#">حریم خصوصی</a></li>
                        <li><a href="#">معامله امن</a></li>
                        <li><a href="#">صفحه فروشندگان</a></li>
                    </ul>
                </div>
                <div className="footer-section footer-social">
                    <div className="social-content">
                        <a href="https://trustseal.enamad.ir/?id=362887&Code=XRxqEoAoj5ZYizgfaNFx">
                            <img src={enamad} alt="Social Media" className="footer-image" />
                        </a>
                        <div className="text-and-icons">
                            <p className="footer-text">در شبکه های اجتماعی کنارتان هستیم !</p>
                            <div className="social-icons">
                                <a href="https://instagram.com/pindo_ir"><FontAwesomeIcon icon={faInstagram} /></a>
                                <a href="https://twitter.com/pindo_ir"><FontAwesomeIcon icon={faTwitter} /></a>
                                <a href="https://www.linkedin.com/company/pindoir/"><FontAwesomeIcon icon={faLinkedin} /></a>
                                <a href="https://twitter.com/pindo_ir"><FontAwesomeIcon icon={faXTwitter} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterTwo;
