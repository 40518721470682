import React from "react";
import "./WheelFortune.css";

//images
import wheelfortune from "../../images/hafez.svg";

const WheelFortune = () => {
    const openInstagram = () => {
        const instagramAppUrl = "instagram://user?username=pindo_ir";
        const instagramWebUrl = "https://instagram.com/pindo_ir";
        window.location.href = instagramAppUrl;
        
        setTimeout(() => {
            window.open(instagramWebUrl, "_blank");
        }, 1000);
    };

    return (
        <div className="wheelfortune-container">
            <img
                src={wheelfortune}
                alt="Wheel Fortune"
                className="wheelfortune-image"
            />
            <div className="wheelfortune-footer">
                <div className="wheelfortune-text">هم فاله، هم تخفیف</div>
                <div className="wheelfortune-button-container">
                    <button
                        className="wheelfortune-button"
                        onClick={openInstagram}
                    >
                        بزن بریم &gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WheelFortune;
