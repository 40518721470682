import React from "react";
import "./Category.css";

// images
import category from "../../images/category.svg";
import ajil from "../../images/cat-ajil.svg";
import arayesh from "../../images/cat-arayesh.svg";
import lebas from "../../images/cat-lebas.svg";
import khone from "../../images/cat-khone.svg";
import farhang from "../../images/cat-farhang.svg";

const categories = [
    { label: "راسته ی لباس فروشا", image: lebas, link: "https://www.pindo.ir/list/apparel/" },
    { label: "راسته آرایشی", image: arayesh, link: "https://www.pindo.ir/list/makeup/" },
    { label: "راسته آجیل و شیرینی", image: ajil, link: "https://www.pindo.ir/list/nuts/" },
    { label: "راسته ی فرهنگ و هنر", image: farhang, link: "https://www.pindo.ir/list/book-and-media/" },
    { label: "راسته کالا دیجیتال", image: category, link: "https://www.pindo.ir/list/electronic-devices/" },
    { label: "راسته ی لوازم خونگی", image: khone, link: "https://www.pindo.ir/list/home-and-kitchen/" },
];

const Category = () => {
    return (
            <div className="category-container">
                {categories.map((cat, index) => (
                    <div className="category-card" key={index}>
                        <a href={cat.link} target="_blank" rel="noopener noreferrer">
                            <div className="category-label">{cat.label}</div>
                            <img src={cat.image} alt={cat.label} className="category-image" />
                        </a>
                    </div>
                ))}
            </div>
    );
};

export default Category;
