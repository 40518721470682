import React from "react";
import FooterOne from "./FooterOne/FooterOne";
import FooterTwo from "./FooterTwo/FooterTwo";
import FooterThree from "./FooterThree/FooterThree";
import FooterFour from "./FooterFour/FooterFour";
import FooterMobile from "./FooterMobile/FooterMobile";
import "./Footer.css";

const Footer = () => {
    return (
        <>
            <div className="footer-desktop">
                <FooterOne />
                <FooterTwo />
                <FooterThree />
                <FooterFour />
            </div>
            <div className="footer-mobile">
                <FooterMobile />
            </div>
        </>
    );
};

export default Footer;
