import React from "react";
import "./FooterMobile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faList,
    faPlus,
    faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-regular-svg-icons";

const FooterMobile = () => {
    return (
        <div className="footer-mobile-container">
            <a href="https://pindo.ir" className="footer-mobile-item">
                <FontAwesomeIcon icon={faHouse} className="footer-mobile-icon" />
                <span className="footer-mobile-text">خانه</span>
            </a>
            <a href="https://www.pindo.ir/main/categories/" className="footer-mobile-item">
                <FontAwesomeIcon icon={faList} className="footer-mobile-icon" />
                <span className="footer-mobile-text">دسته بندی</span>
            </a>
            <a href="https://www.pindo.ir/create/" className="footer-mobile-item">
                <FontAwesomeIcon icon={faPlus} className="footer-mobile-icon" />
                <span className="footer-mobile-text">بفروش</span>
            </a>
            <a href="https://www.pindo.ir/chat/" className="footer-mobile-item">
                <FontAwesomeIcon icon={faMessage} className="footer-mobile-icon" />
                <span className="footer-mobile-text">چت</span>
            </a>
            <a href="https://pindo.ir" className="footer-mobile-item">
                <FontAwesomeIcon
                    icon={faRightToBracket}
                    className="footer-mobile-icon rotate-icon"
                />
                <span className="footer-mobile-text">ورود/عضویت</span>
            </a>
        </div>
    );
};

export default FooterMobile;
