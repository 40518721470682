import React, { useState, useEffect } from "react";
import "./MostBuyers.css";

const MostBuyers = () => {
    const [timeLeft, setTimeLeft] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    });

    const [buyers, setBuyers] = useState([]);

    useEffect(() => {
        const fetchBuyers = async () => {
            const response = await fetch("/yalda/yalda.json");
            const data = await response.json();
            setBuyers(data);
        };
        fetchBuyers();
    }, []);
    

    useEffect(() => {
        const targetDate = new Date("2024-12-20T23:59:59").getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = targetDate - now;

            if (distance < 0) {
                clearInterval(interval);
                return;
            }

            const days = String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(2, "0");
            const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
            const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
            const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, "0");

            setTimeLeft({ days, hours, minutes, seconds });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="mostbuyers-container">
            <div className="mostbuyers-title">
                طولانی ترین لیست خرید تا امروز :
            </div>
            <div className="mostbuyers-table">
                <div className="table-header">
                    <div className="table-heads">
                        <div className="table-tbody-cell header-cell rank">رتبه</div>
                        <div className="table-tbody-cell header-cell name">نام نام خانوادگی</div>
                        <div className="table-tbody-cell header-cell items">تعداد اقلام</div>
                        <div className="table-tbody-cell header-cell amount">مبلغ به تومان</div>
                    </div>
                </div>
                <div className="table-body">
                    <div className="table-tbody">
                        {buyers.map((buyer) => (
                            <div className="table-row" key={buyer.rank}>
                                <div className="table-cell rank rank-value">{buyer.rank}</div>
                                <div className="table-cell name name-value">{buyer.name}</div>
                                <div className="table-cell items items-value">{buyer.items}</div>
                                <div className="table-cell amount amount-value">{buyer.amount}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="mostbuyers-countdown">
                <div className="countdown-timer">
                    <div className="countdown-item">
                        <span className="countdown-label">ثانیه</span>
                        <span className="countdown-value">{timeLeft.seconds}</span>
                    </div>
                    <span className="countdown-separator">:</span>
                    <div className="countdown-item">
                        <span className="countdown-label">دقیقه</span>
                        <span className="countdown-value">{timeLeft.minutes}</span>
                    </div>
                    <span className="countdown-separator">:</span>
                    <div className="countdown-item">
                        <span className="countdown-label">ساعت</span>
                        <span className="countdown-value">{timeLeft.hours}</span>
                    </div>
                    <span className="countdown-separator">-</span>
                    <div className="countdown-item">
                        <span className="countdown-label">روز</span>
                        <span className="countdown-value">{timeLeft.days}</span>
                    </div>
                </div>
                <span className="countdown-titel">مانده&nbsp;تا&nbsp;پایان&nbsp;کمپین</span>
            </div>
        </div>
    );
};

export default MostBuyers;
