import React from "react";
import "./SeeBuy.css";

// Import images
import YaldaGift from "../../images/YaldaGift.svg";
import YaldaOff from "../../images/YaldaOff.svg";

const SeeBuy = () => {
    return (
        <div className="see-buy-container">
            {/* <a href="https://example.com/gift" target="_blank" rel="noopener noreferrer">
                <img src={YaldaGift} alt="Yalda Gift" className="see-buy-image" />
            </a> */}
            <a href="https://www.pindo.ir/list/yalda/" target="_blank" rel="noopener noreferrer">
                <img src={YaldaOff} alt="Yalda Off" className="see-buy-image" />
            </a>
        </div>
    );
};

export default SeeBuy;
