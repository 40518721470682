import React from "react";
import "./LuckPart.css";

// components
import WheelFortune from "../WheelFortune/WheelFortune";
import MostBuyers from "../MostBuyers/MostBuyers";

const LuckPart = () => {
    return (
        <div className="luckpart-container">
            <div className="luckpart-box">
                <MostBuyers />
            </div>
            <div className="luckpart-box">
                <WheelFortune />
            </div>
        </div>
    );
};

export default LuckPart;
