import React from "react";
import "./Slider.css";

// img
import image from "../../images/slider.svg";

const Slider = () => {
  return (
    <div className="slider-container">
      <img
        src={image}
        alt="slider"
        className="slider-image"
      />
    </div>
  );
};

export default Slider;
