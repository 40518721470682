import React from "react";
import "./FooterThree.css";

// Import images
import apk from "../../../images/apk.svg";
import bazar from "../../../images/bazar.svg";
import mayket from "../../../images/mayket.svg";
import googleplay from "../../../images/googleplay.svg";

const FooterThree = () => {
    return (
        <div className="footer-three-container">
            <div className="footer-three-content">
                <div className="footer-text-app">
                    دانلود اپلیکیشن پیندو
                </div>
                <div className="footer-app-links">
                    <div className="app-image">
                        <p>دانلود مستقیم نسخه آندروید</p>
                        <img src={apk} alt="App 1" />
                    </div>
                    <img src={bazar} alt="App 2" className="app-image" />
                    <img src={googleplay} alt="App 4" className="app-image" />
                    <img src={mayket} alt="App 3" className="app-image" />
                </div>
            </div>
        </div>
    );
};

export default FooterThree;
