import React from "react";
import "./FooterFour.css";

const FooterFour = () => {
    return (
        <div className="footer-four-container">
            <div className="footer-four-content">
                استفاده از محتوای پیندو برای اهداف غیرتجاری و با ذکر منبع ، ایرادی ندارد . تمام حقوق این سایت متعلق به پیندو است .
            </div>
        </div>
    );
};

export default FooterFour;
