
import './App.css';
import Yalda from './pages/landing/Yalda';

function App() {
  return (
    <Yalda />
  );
}

export default App;
