import React from "react";
import "./FooterOne.css";

// Import the logo
import logo from "../../../images/logo.svg";

// Font Awesome Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FooterOne = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="footer-one-container">
            <div className="footer-one-content">
                <div className="footer-left">
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="footer-logo" />
                    </div>
                    <div className="support-info">
                        شماره پشتیبانی : ۶۱۹۳۰۲۰۰-۰۲۱  &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; هفت روز هفته (حتی روزهای تعطیل) ، ۲۴ ساعته پاسخگوی شما هستیم .
                    </div>
                </div>
                <div className="footer-right">
                    <button className="scroll-to-top" onClick={scrollToTop}>
                        بازگشت به بالا
                        <FontAwesomeIcon icon={faChevronUp} className="chevron-icon" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FooterOne;
